.tree-name-info-family { 
    font-family: 'urbane'
}

.tree-name-info-science-name, .tree-name-info-right-text p {
    line-height: 1.1;
}

.tree-name-info-science-name p {
    text-align: center;
}

@media(max-width: 767px) {
    .tree-name-info-left-text:after, .tree-name-info-middle-text:after, .tree-name-info-right-text:after, .tree-name-info-science-name:after {
        content: "";
        border-bottom: 1px solid black !important;
        position: absolute;
        bottom: 0px;
        left: 40%;
        right: 40%;
    }

    .tree-name-info-family {
        font-size: 1rem !important;
    }
}

@media(max-width:767px) {
    .tree-name-info-left-text {
        text-align: center;
    }
    .tree-name-info-middle-text {
        text-align: center;
    }
    .tree-name-info-right-text {
        text-align: center;
    }
    
    .tree-name-info-left-text, .tree-name-info-middle-text, .tree-name-info-right-text {
        padding:1rem 1rem 0;
    }

    .tree-name-info-left-text h2, .tree-name-info {
        border-bottom: 3px solid black !important;
        padding-bottom: 1rem;
        margin: 0;
    }

    .tree-name-info-science-name:after {
        content: "";
        border-bottom: 1px solid black !important;
        position: absolute;
        bottom: 0px;
        left: 20%;
        right: 20%;
    }
    
    .tree-name-info-left-text h4, .tree-name-info-middle-text h4, .tree-name-info-right-text h4{
        font-size: .8rem;
    }

    .tree-name-info-left-text h4 {
        margin: 0;
    }
    
    .tree-name-info {
        padding-top: 0rem;
    }
    
    .tree-name-info-family {
        display:block;
        margin: .5rem 0 1rem;
    }

    .tree-name-info-science-name {
        position:relative;
        padding: 1rem 0;
    }
}

@media(min-width:768px) {
    .tree-name-info-left-text {
        text-align: left;
    }
    .tree-name-info-middle-text {
        text-align: center;
    }
    .tree-name-info-right-text {
        text-align: right;
    }

    .tree-name-info-right-text:after {
        content: "";
        border-left: 4px solid black !important;
        position: absolute;
        top: 0px;
        bottom: 40px;
        left: 0px;
    }

    .tree-name-info-left-text:after {
        content: "";
        border-right: 4px solid black !important;
        position: absolute;
        top: 0px;
        bottom: 40px;
        right: 0px;
    }

    .tree-name-info {
        padding-top: 3rem;
    }

    .tree-name-info-left-text h4 {
        font-size: 1rem;
    }



}
