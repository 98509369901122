.result-counter-counter{
    font-family:'urbane',sans-serif;
    font-weight:500;
    font-style:normal;
    font-size:2rem;
}

.result-counter-counter > .count{
    font-weight:700;
    font-size:3rem;
}
