/* this file is for css styles that need to apply across the entire app */

h1,
.category-label,
.label-bold {
  font-family: 'urbane', sans-serif;
  font-weight: 700;
  font-style: normal;
}

p {
  font-family: 'open-sans';
}

ul {
  padding-inline-start: 0px;
  text-align: left;
}

li {
  text-decoration: none;
}

.label-demibold {
  font-family: 'urbane', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.form-label,
.featured-tree-label,
.label-medium {
  font-family: "urbane", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

.less-line-spacing {
  line-height: .8;
}

.text-lg {
  font-size: 1.5rem;
}

.ul-grid {
  text-align: left;
}

.ul-grid-centered {
  text-align: center;
}

.ul-grid li,
.ul-grid-centered li {
  display: inline-block;
  text-decoration: none;
}

.ul-grid-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-check input {
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 20px;
  z-index: 2;
  border-radius: 5px;
}

.form-check .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #457537;
  /* transform: rotate(-90deg); */
  transition: all 0.3s;
}

.form-check .overlay .icon {
  color: #457537;
  display: none;
}

.form-check input:checked~.overlay {
  background-color: #ffffff;
  border-radius: 5px;
  transform: rotate(0deg);
  opacity: 1;
  border: 2px solid #457537;
}

.form-check input:checked~.overlay .icon {
  display: block;
  height: 1.25em;
  width: 1.25em;
  position: relative;
  top: -2px;
  left: -2px;
  border-radius: 5px;
}

/* overwrite rc.slider components */

.rc-slider {
  padding: 0px;
  margin: 42px 6% 32px;
  width: 88%;
}

.rc-slider-rail {
  border: 1px solid black;
  height: 8px;
}

.rc-slider-track {
  height: 12px;
  background-color: #457537;
  margin-top: -2px;
}

.rc-slider-handle {
  background-color: #457537;
  border: 5px solid white;
  box-shadow: 0 0 0 1px black;
  margin-top: -8px;
  height: 24px;
  width: 24px;
}

.rc-slider-dot {
  /* Setting opacity to 0 lets the user still click on the bar to change the slider without showing the ugly dot. */
  opacity: 0;
}

.rc-slider-dot-active {
  background-color: #457537;
  border: 1px solid #457537;
}

.rc-slider-handle:focus {
  background-color: #457537;
  border: 5px solid white;
  box-shadow: 0 0 0 1px black;
}

.rc-slider-handle:hover {
  border: 6px solid white;
  box-shadow: 0 0 0 1px black;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border: 8px solid #457537;
  background-color: #e4e4e4;
  box-shadow: 0 0 0 0px #457537;
}

.rc-slider-tooltip-inner {
  padding: 10px;
  color: white;
  background-color: #457537;
  height: auto;
}

.hidden {
  display: none !important;
  height: 0 !important;
  max-height: 0 !important;
}

.form-check-inline input,
.form-check-inline label {
  cursor: pointer;
}

@media (min-width:1900px) {


  .app-container {
    max-width: 1920px !important;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .selectree-navbar {
    max-width: 95%;
    position: relative;
    margin: auto;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
  }

  .bigtree-navbar {
    width: 100%;
  }
}

@media (min-width:992px) {
  .equal-height-columns-2 {
    flex: 0 0 50%;
    /* max-width: 50%; */
    position: relative;
    /* width: 100%; */
    display: flex;
  }

  .equal-height-columns-3 {
    flex: 0 0 33%;
    position: relative;
    display: flex;
  }
}

@media (max-width: 991px) {
  body {
    text-align: center;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0;
  }
}

.hr-bold-short-white {
  background-color: white;
  width: 20%;
  margin: 3rem auto;
  height: 4px;
}

.hr-bold-short-black {
  background-color: black;
  width: 20%;
  margin: 3rem auto;
  height: 4px;
}

.hr-bold-short-black-left {
  background-color: black;
  width: 20%;
  margin: 1rem auto 1rem 0;
  height: 4px;
}

/* Change the size of the category text on small screens.*/

@media(max-width:992px) {
  h2 {
    font-size: 1.6rem
  }

  .category-label {
    font-size: 1.2rem;
  }
}

.btn {
  padding: .15rem 1.5rem;
  text-transform: uppercase;
}

@media(max-width:360px) {
  .category-label {
    font-size: 1rem;
  }

  .hr-bold-short-white {
    width: 40%;
  }

  .hr-bold-short-black {
    width: 40%;
  }

  .hr-bold-short-black-left {
    height: 2px;
    width: 80%;
    margin: 1rem auto;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #659557;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #457537;
  cursor: pointer;
}

.accordion>.card {
  overflow: visible !important;
}

.form-button-primary {
  cursor: pointer;
  background-color: #457537;
  display: inline-block;
  padding: .25rem .5rem;
  color: white;
  border-radius: .25rem;
}

.form-button-secondary {
  cursor: pointer;
  background-color: #ccc;
  display: inline-block;
  padding: .25rem .5rem;
  color: black;
  border-radius: .25rem;
}

.form-field-text-inline {
  display: flex;
  align-items: center;
  height: 38px;
}


.form-field-text-inline>* {
  min-width: 160px;
  margin-bottom: 0;
}

.form-field-text-inline>.btn {
  height: 100%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-field-text-inline>input {
  height: 100%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}