th {
   white-space: nowrap;
   color: #2D2D2D;
}

.tr-even {
   background-color: #F5F5F5;
}
.tr-odd{
   background-color: white;
}

td {
  color: #2D2D2D;
}
