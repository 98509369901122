.result-card{
    border:none;

}

.results-page-big-search-icon{
    width:100px;
}

.result-card-scientific-name{
    margin-top:-12px;
}

.result-card-img{
    height:256px;
    max-height:256px;
    object-fit:cover;
    border-radius:3px;
}

.result-card-img-container {
    position:relative;
}

.result-card-icons {
    position:absolute;
    top: 0;
}

.result-card-icons img {
    display: inline-block;
    width: 58px;
    height: 58px;
    margin: .5rem;
    margin-right: 0;
}