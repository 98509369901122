#login-modal-background {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: rgba(255,255,255,.85);
    z-index: 10002 !important;
    display:flex;
    justify-content: center;
    align-items: center;
}

.login-modal-main {
    margin:auto;
    width:fit-content;
    cursor: pointer;
    background-color: white;
    border: 2px solid #444;
    border-radius: 4px;
    padding: 3rem;
    position:relative;
}

.login-modal-main h2 {
    font-family: 'urbane', sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
}

.login-modal-button {
    padding: 15px 20px;
    border-radius: 4px;
    border: 2px solid black;
    background-color: white;
    color: #666;
    display:inline-block;
    cursor: pointer;
    z-index: 10003;
    position:absolute;
    margin-left: -30px;
    top: calc(50% - 30px);
}

.login-modal-button:hover {
    background-color: #eaeaea;
    color: black;
}

.login-modal-close.btn {
    cursor: pointer;
    top:0;
    right:0;
    padding: 5px 13px !important;
    border-radius: 100%;
    position:absolute;
    margin:2rem;
    border:2px solid black;
    box-shadow: 0 0 3px 1px #eaeaea;
}

#login-menu-no_user::after {
      display: none;
}

@media(min-width: 75em) {
   .login-nav-menu {
      float: right;
   }
}

@media(max-width: 720px) {

    .login-modal-button {
        padding: 5px 10px;
        margin-left: -20px;
        top: calc(50% - 20px);
    }

    .login-modal-main {
        padding: 1rem;
        padding-top:4rem;
    }
    .login-modal-close.btn {
        margin:1rem;
    }
}
