.tree-detail--tree-info {
    text-align: left;
}

.home-stats h2, .home-stats h5 {
    font-family: 'urbane';
    font-weight: 700 !important;
}

.tree-detail--tree-name-info>.container, .tree-detail--tree-detail-gallery>.container {
    padding: 1rem 1.7rem !important;
}

.tree-detail--nursury-warning {
    border: 3px solid #457537;
    border-radius:4px;
}

@media(min-width: 576px) {
    .tree-detail--nursury-warning {
        display: flex;
    }
}
