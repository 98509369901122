.bigtree-banner-container-h1 {
  font-size: 3rem;
  text-shadow: -1px 1px 10px #fff;
}

.bigtree-banner-container-h3 {
  font-family: 'urbane', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: -1px 1px 10px #fff;
}

.bigtree-banner-container {
  height: 170px;
  background: url("/images/bt_header_02_600.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  display: flex;
  align-items: center;
  padding: 2rem;
  margin-top: 74px;
}

@media(max-width:992px) {
  .bigtree-banner-container-h1 {
    font-size: 2.2rem;
  }

  .bigtree-banner-container-h3 {
    font-size: 1.2rem;
  }

  .bigtree-banner-container {
    height: auto;
    background: url("/images/bt_header_02_300_wash.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    text-align: left;
    padding: 1rem;
    align-items: center;
  }

  .bigtree-banner-container {
    margin-top: 114px;
  }
}

.bigtree-banner-title {
  display: block;
}
