.quicklinks-container {
  display: flex;
  justify-content: space-evenly;
  padding: .5rem 10px;
}

.quick-links-link-text {
  margin-top: 16px;
}

.btn-circle-md {
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 38px;
  text-align: left;
  color: white;
  background-color: gray;
}

.btn-icon {
    height: 19px;
    width: 19px;
    margin-top: 9px;
    margin-left: 9px;
}

.quicklinks-link:link {
  text-decoration: none;
}

/* added this to temporarily remove nominator form */
a:hover {
  text-decoration: none;
}

@media (max-width:992px) {
  /* The mobile version puts the styling on the link itself*/
  .quicklinks-link {
    border: 1px solid #666;
    border-radius: 4px;
    background-color: #F5F5F5;
    height: 100%;
    text-align: center;
    display: block;
  }
  .quicklinks-link-icon {
    background-color: transparent;
    color: #457537;
  }
  .quicklinks-link-text {
    color: #444;
  }
  .btn-circle-md {
    margin-top: 16px;
  }
}

@media (min-width:992px) {
  .quicklinks-bg {
    background-color: #F5F5F5;
  }
  .quicklinks-link-text {
    color: white;
  }
  .quicklinks-link {
    display: flex;
    align-items: flex-start;
  }
  .quicklinks-link>p {
    padding: 0 15px 0 15px;
    font-size: 14px !important;
  }
  .quicklinks-link-text {
    color: #444;
    width: 100%;
  }

  .quicklinks-container > .row {
    width:100% !important;
  }
}
