.bigtree-mobile-hr {
    height: 1px;
    width: 100%;
    margin-bottom: 3px;
}

.home-stats {
    align-items: center;
}
.home-stats h2, .home-stats h5 {
    font-family: 'urbane';
    font-weight: 700 !important;
}

.home-stats .hs-small {
    font-size: 70% !important;
}

@media(max-width:991px) {
    .bigtree-row-container {
        justify-content: center;
    }
}

@media(min-width:992px) {
    .bigtree-row-container {
        justify-content: left;
    }
}
