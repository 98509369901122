.footer-images {
    display: flex;
    justify-content: space-between;
}

.footer-img{
width:100%;

}

.footer {
    text-align: center;
    font-style: italic;
}
