#modal-background {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,.75);
    z-index: 9999;
    display:flex;
    justify-content: center;
    align-items: center;
}


.point_score {
   text-align: right;
}

@media only screen and (max-width: 62em) {
   .point_score {
      text-align: center;
   }
}

#modal-background img {
    max-height: 95vh;
    max-width: 85vw;
    cursor: pointer;
    z-index: 10000;
    height:auto;
    width:auto;
}

.modal-main {
    margin:auto;
    width:fit-content;
    cursor: pointer;
    position:relative;
}


.main-gallery-image {
   display: block;
   margin: 0 auto;
   border: 2px solid #F5F5F5;
   height: auto;
   max-width: 70%;
}

.gallery-image {
   display: block;
   width: 100%;
   border: 2px solid #F5F5F5;
}

.carousel-image {
   display: block;
   width: 100vw;
   height: 100vh;
   margin: 0 auto;
}

.carousel-inner {
   background-color: rgba(0,0,0,0);
}

.slide {
   background-color: rgba(0,0,0,0);
   width: 100vw;
}

.carousel {
   width: 100vw;
}

.carousel-control-prev, .carousel-control-next {
   margin: auto 2rem;
   width: 5rem;
   height: 5rem;
   display: block;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
   display: block;
   margin: 2rem auto;
}
