.result-pagination {
    display: flex;
    flex-flow: column wrap;
}

.result-pagination-btn {
    padding: 0 1rem;
}

.result-pagination .btn-primary.active {
    background-color: #457537 !important;
    color: #f5f5f5 !important;
}

.result-pagination .btn-primary:not(.active) {
    background-color: white;
    color: #444;
}

.result-pagination .btn-primary:hover {
    background-color: #315227;
    color: white;
}

.result-pagination-buttons {
    display: flex;
    width: fit-content;
}

@media(max-width: 991px) {
    .result-pagination-buttons {
        margin: auto;
    }
}

@media(min-width: 992px) {
    .result-pagination-buttons {
        display: inline-flex;
    }
}

@media(min-width: 992px) and (max-width: 1280px) {
    .result-pagination-buttons .result-pagination-btn {
        padding: 0 .5rem;
    }
}
