.result-ops-sort {
    justify-content: right;
}

.result-counter-counter {
    font-family:'urbane',sans-serif;
    font-weight:500;
    font-style:normal;
    font-size:2rem;
}

.result-counter-counter > .count{
    font-weight:700;
    font-size:3rem;
}

@media(max-width: 991px) {
    .result-ops-pagination-container {
        text-align:center;
    }

    .mt-5 {
        margin-top: 0.5rem !important; 
    }

    .mt-5 {
        margin-bottom: 0.5rem !important; 
    }
}
@media(min-width: 992px) {
    .result-ops-pagination-container {
        text-align:left;
    }

    .mt-5 {
        margin-top: 0.5rem !important; 
    }

    .mt-5 {
        margin-bottom: 0.5rem !important; 
    }
}
